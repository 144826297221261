import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { NavItem } from 'reactstrap';

import TooltipItem from '../tooltip/CustomTooltipItem';

const NavBarItem = ({ option, path, icon, target, tooltipText }) => {
  return (
    <NavItem>
      <Link
        to={path}
        className={classNames({
          active: option,
        })}
      >
        <span>
          <i className={icon} id={target} />
          <TooltipItem target={target} placement="right">
            <FormattedMessage id={tooltipText} />
          </TooltipItem>
        </span>
      </Link>
    </NavItem>
  );
};

NavBarItem.propTypes = {
  option: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  target: PropTypes.string.isRequired,
  tooltipText: PropTypes.string.isRequired,
};

NavBarItem.defaultProps = {};

export default NavBarItem;
