/**
 * Helper para la creación de actions.
 * Ayuda a facilitar la creación de mensajes {type: n, payload: k}
 * @param type Identificador de la acción
 * @param payload Parametros de la acción
 * @returns {{type: *, payload: *}}
 */
export default (type, payload = null) => ({
  type,
  payload,
});
