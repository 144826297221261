import { STATE } from '../../config/constants';
import COINS from '../actions/coins';

const defaultState = {
  error: null,
  state: STATE.NONE,
  coin: null,
};

const ACTION_HANDLERS = {
  [COINS.SET_STATE]: (state, action) => ({
    ...state,
    ...action.payload,
    state: STATE.NONE,
  }),

  [COINS.GET_COIN_REQUEST]: (state) => {
    return {
      ...state,
      state: STATE.FETCHING,
    };
  },
  [COINS.GET_COIN_SUCCESS]: (state, action) => {
    // some transformations

    return {
      ...state,
      coin: action.payload.coin,
      state: STATE.SUCCESS,
      error: null,
    };
  },

  [COINS.GET_COIN_FAILURE]: (state, action) => {
    const { payload: error } = action;
    return {
      ...state,
      state: STATE.FAILURE,
      error,
    };
  },
};

export default (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
