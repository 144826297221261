const COIN_LIST_ACTIONS_TYPES = {
  SET_STATE: 'SET.STATE',

  COIN_LIST_REQUEST: 'COIN_LIST.REQUEST',
  COIN_LIST_SUCCESS: 'COIN_LIST.SUCCESS',
  COIN_LIST_FAILURE: 'COIN_LIST.FAILURE',
  COIN_LIST_NEW: 'COIN_LIST.NEW',

  GET_COIN_REQUEST: 'GET_COIN.REQUEST',
  GET_COIN_SUCCESS: 'GET_COIN.SUCCESS',
  GET_COIN_FAILURE: 'GET_COIN.FAILURE',
};

export default COIN_LIST_ACTIONS_TYPES;
