import React from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter as Router } from 'connected-react-router';
import { injectIntl } from 'react-intl';

import routes from '../routes';
import { history } from '../starter';
import AppLayout from './AppLayout';
import PrivateRoute from './PrivateRoute';
import axios from '../config/axios';
import PublicRoute from './PublicRoute';

const App = ({ intl: { formatMessage } }) => {
  const handleTitleMessage = (dispatch) => {
    document.title = formatMessage({
      id: 'title',
    });
    axios.setParams(dispatch, formatMessage);
  };

  return (
    <Router history={history}>
      <AppLayout onRendered={handleTitleMessage}>
        {routes.map((route) => {
          const { path, component, exact, isPrivate, provider } = route;
          return isPrivate ? (
            <PrivateRoute
              key={path}
              path={path}
              component={component}
              exact={exact}
              provider={provider}
            />
          ) : (
            <PublicRoute
              key={path}
              path={path}
              exact={exact}
              component={component}
              provider={provider}
            />
          );
        })}
      </AppLayout>
    </Router>
  );
};

App.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(App);
