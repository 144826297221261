import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { path, exact, computedMatch, provider: LocalProvider } = rest;

  return (
    <Route
      path={path}
      exact={exact}
      computedMatch={computedMatch}
      render={() => {
        return LocalProvider ? (
          <LocalProvider key={path}>
            <RouteComponent />
          </LocalProvider>
        ) : (
          <RouteComponent />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

PrivateRoute.defaultProps = {};

export default PrivateRoute;
