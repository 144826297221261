import './CalculatorHome.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import {
  Button,
  Col,
  Row,
  Spinner,
  Toast,
  ToastBody,
  ToastHeader,
} from 'reactstrap';

import actionCreator from '../../commons/action-helpers';
import CurrencyInput from '../../components/inputs/CurrencyInput';
import { STATE } from '../../config/constants';
import COIN_ACTIONS from '../../state/actions/coins';

const commonMask = {
  prefix: '',
  thousandsSeparatorSymbol: '',
  decimalSymbol: '.',
};

const CalculatorHome = ({ intl: { formatMessage } }) => {
  const dispatch = useDispatch();
  const [usdt, setUsdt] = useState();
  const [listOk, setListOk] = useState(false);
  const [results, setResults] = useState({
    coin1: 0,
    coin2: 0,
    coin3: 0,
    coin4: 0,
  });
  const [coinsPrices, setCoinsPrices] = useState({
    coin1: 0,
    coin2: 0,
    coin3: 0,
    coin4: 0,
  });
  const [coins, setCoins] = useState({
    coin1: '',
    coin2: '',
    coin3: '',
    coin4: '',
  });
  const [currentCoin, setCurrentCoin] = useState('');

  const recalculate = (newUsdt) => {
    Object.keys(coinsPrices).forEach((coin) => {
      if (coinsPrices[coin] > 0) {
        results[coin] = newUsdt / coinsPrices[coin];
      }
    });
  };

  const { coinList, getCoin } = useSelector(
    (stateRdx) => ({
      coinList: stateRdx.coinList,
      getCoin: stateRdx.getCoin,
    }),
    shallowEqual,
  );

  useEffect(() => {
    dispatch(actionCreator(COIN_ACTIONS.COIN_LIST_REQUEST));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (coinList.state === STATE.SUCCESS || coinList.state === STATE.UPDATED) {
      let shouldRecalculate = false;
      Object.keys(results).forEach((key) => {
        if (results[key] !== 0) {
          shouldRecalculate = true;
        }
      });
      if (shouldRecalculate) {
        recalculate(usdt);
      }
      setListOk(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coinList]);

  useEffect(() => {
    if (getCoin.state === STATE.SUCCESS) {
      const { coin } = getCoin;
      setCoins({
        ...coins,
        [currentCoin]: { ...coin },
      });
      setCoinsPrices({
        ...coinsPrices,
        [currentCoin]: coin.price,
      });
    }
    if (getCoin.state === STATE.FAILURE) {
      setCoins({
        ...coins,
        [currentCoin]: '',
      });
      setCurrentCoin('');
      console.error('Fallo obteniendo', getCoin.error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCoin]);

  const handleCalculate = () => {
    dispatch(actionCreator(COIN_ACTIONS.COIN_LIST_REQUEST));
  };

  const handleUsdt = (e) => {
    let newUsdt = e.target.value;
    newUsdt = newUsdt.replaceAll(',', '');
    setUsdt(newUsdt);
    recalculate(newUsdt);
  };

  const handleChange = (name, e) => {
    if (e !== null) {
      if (e.__isNew__) {
        const { value } = e;
        setCurrentCoin(name);
        setCoins({
          ...coins,
          [name]: {
            label: value.toUpperCase(),
          },
        });
        dispatch(actionCreator(COIN_ACTIONS.GET_COIN_REQUEST, value));
      } else {
        setCoinsPrices({
          ...coinsPrices,
          [name]: e.price,
        });
        setCoins({ ...coins, [name]: e });
        const calc = {};
        const localUsdt = usdt || 0;
        if (e !== null) {
          calc[name] = localUsdt / e.price;
          setResults({
            ...results,
            ...calc,
          });
        } else {
          calc[name] = 0;
          setResults({
            ...results,
            ...calc,
          });
        }
      }
    } else {
      setCoinsPrices({
        ...coinsPrices,
        [name]: '',
      });
      setCoins({ ...coins, [name]: '' });
      setResults({
        ...results,
        [name]: '',
      });
    }
  };

  return (
    <div className="container-fluid calc">
      {coinList.state === STATE.FAILURE && (
        <Toast>
          <ToastHeader icon="danger">
            {formatMessage({
              id: 'errorTitle',
            })}
          </ToastHeader>
          <ToastBody>
            {formatMessage({
              id: 'errorMessage',
            })}
          </ToastBody>
        </Toast>
      )}
      {coinList.state === STATE.FETCHING && (
        <Spinner size="sm" color="primary" />
      )}
      {listOk && (
        <>
          <br />
          <Row className="rowSpace">
            <Col md={6} sm={6}>
              <CurrencyInput
                maskOptions={{
                  ...commonMask,
                  thousandsSeparatorSymbol: ',',
                }}
                className="inputSpace"
                name="usdt"
                id="usdt"
                placeholder="0.00"
                type="text"
                onChange={handleUsdt}
                value={usdt}
              />
            </Col>
            <Col md={6} sm={6}>
              {formatMessage({
                id: 'USDT',
              })}
            </Col>
          </Row>
          <Row className="rowSpace">
            <Col md={6} sm={6}>
              <CurrencyInput
                maskOptions={commonMask}
                className="inputSpace"
                value={results.coin1}
                name="coin1"
                id="coin1"
                placeholder="0.00"
                type="text"
              />
            </Col>
            <Col md={6} sm={6}>
              <CreatableSelect
                isClearable
                options={coinList.list}
                value={coins.coin1}
                className="basic-single"
                classNamePrefix="select"
                onChange={(e) => handleChange('coin1', e)}
              />
            </Col>
          </Row>
          <Row className="rowSpace">
            <Col md={6} sm={6}>
              <CurrencyInput
                maskOptions={commonMask}
                className="inputSpace"
                value={results.coin2}
                name="coin2"
                id="coin2"
                placeholder="0.00"
                type="text"
              />
            </Col>
            <Col md={6} sm={6}>
              <CreatableSelect
                isClearable
                options={coinList.list}
                value={coins.coin2}
                className="basic-single"
                classNamePrefix="select"
                onChange={(e) => handleChange('coin2', e)}
              />
            </Col>
          </Row>
          <Row className="rowSpace">
            <Col md={6} sm={6}>
              <CurrencyInput
                maskOptions={commonMask}
                className="inputSpace"
                value={results.coin3}
                name="coin3"
                id="coin3"
                placeholder="0.00"
                type="text"
              />
            </Col>
            <Col md={6} sm={6}>
              <CreatableSelect
                isClearable
                options={coinList.list}
                value={coins.coin3}
                className="basic-single"
                classNamePrefix="select"
                onChange={(e) => handleChange('coin3', e)}
              />
            </Col>
          </Row>
          <Row className="rowSpace">
            <Col md={6} sm={6}>
              <CurrencyInput
                maskOptions={commonMask}
                className="inputSpace"
                value={results.coin4}
                name="coin4"
                id="coin4"
                placeholder="0.00"
                type="text"
              />
            </Col>
            <Col md={6} sm={6}>
              <CreatableSelect
                isClearable
                options={coinList.list}
                value={coins.coin4}
                className="basic-single"
                classNamePrefix="select"
                onChange={(e) => handleChange('coin4', e)}
              />
            </Col>
          </Row>
          <Row className="rowSpace">
            <Col md={12}>
              <Button
                title={formatMessage({
                  id: 'recalculate',
                })}
                onClick={handleCalculate}
              >
                {formatMessage({
                  id: 'recalculate',
                })}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

CalculatorHome.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(CalculatorHome);
