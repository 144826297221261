import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Nav, Navbar, NavbarBrand } from 'reactstrap';

import NavBarItem from './CustomNavBarItem';
import './CustomNavBar.scss';

const CustomNavBar = ({ logo, altLogo, items }) => {
  let key = Math.floor(Math.random() * 100);
  return (
    <Navbar
      role="navigation"
      className={classNames({
        'cp-navbar': true,
        'cp-navbar-collapsed': true,
      })}
    >
      <NavbarBrand>
        <img src={logo} alt={altLogo} />
      </NavbarBrand>
      <Nav navbar>
        {items.map((item) => {
          key += 1;
          return (
            <NavBarItem
              key={`item-${key}`}
              option={item.option}
              path={item.path}
              icon={item.icon}
              target={item.target}
              tooltipText={item.tooltipText}
            />
          );
        })}
      </Nav>
    </Navbar>
  );
};

CustomNavBar.propTypes = {
  logo: PropTypes.string.isRequired,
  altLogo: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      option: PropTypes.bool,
      path: PropTypes.string,
      target: PropTypes.string,
      tooltipText: PropTypes.string,
    }),
  ).isRequired,
};

CustomNavBar.defaultProps = {
  altLogo: 'logo',
};

export default CustomNavBar;
