import { ascend, prop, sort } from 'ramda';

import { STATE } from '../../config/constants';
import COINS from '../actions/coins';

const defaultState = {
  error: null,
  state: STATE.NONE,
  list: null,
};

const sortList = (list, customAttr = 'symbol') => {
  const byLabel = ascend(prop('label'));

  const tmpList = list.map((coin) => {
    return {
      label: coin[customAttr].toUpperCase(),
      value: coin.id || coin.value,
      price: coin.current_price || coin.price,
      updatedAt: coin.last_updated || coin.updatedAt,
    };
  });

  return sort(byLabel, tmpList);
};

const ACTION_HANDLERS = {
  [COINS.SET_STATE]: (state, action) => ({
    ...state,
    ...action.payload,
    state: STATE.NONE,
  }),

  [COINS.COIN_LIST_REQUEST]: (state) => {
    return {
      ...state,
      state: STATE.FETCHING,
    };
  },

  [COINS.GET_COIN_SUCCESS]: (state, action) => {
    const { coin } = action.payload;

    state.list.push(coin);

    const sortedList = sortList(state.list, 'label');

    return {
      ...state,
      list: sortedList,
      state: STATE.UPDATED,
    };
  },

  [COINS.COIN_LIST_SUCCESS]: (state, action) => {
    // some transformations

    return {
      ...state,
      list: sortList(action.payload.list),
      state: STATE.SUCCESS,
      error: null,
    };
  },

  [COINS.COIN_LIST_FAILURE]: (state, action) => {
    const { payload: error } = action;
    return {
      ...state,
      state: STATE.FAILURE,
      error,
    };
  },
};

export default (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
