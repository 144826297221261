import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl-redux';
import { Provider } from 'react-redux';
import { Col, Row } from 'reactstrap';

import logo from '../assets/images/logocalc.svg';
import { DEV, TODAY } from '../config/constants';
import config from '../config/vars';
import starter from '../starter';
import App from './App';

const AppContainer = () => {
  const initialState = {
    loading: true,
    failure: false,
    text: '',
  };

  const [currentState, setCurrentState] = useState(initialState);

  const startApp = () => {
    try {
      starter.initModules();
      setCurrentState({
        loading: false,
        failure: false,
      });
    } catch (e) {
      setCurrentState({
        loading: false,
        failure: process.env.NODE_ENV !== DEV,
        text: 'ERROR: Setting the environment variables',
      });
    }
  };

  useEffect(() => {
    if (process.env.NODE_ENV !== DEV) {
      fetch(`/environment.json?v=${TODAY.getTime()}`)
        .then((response) => response.json())
        .then(
          (result) => {
            config.setEnvironment(result);
            startApp();
          },
          (error) => {
            setCurrentState({
              loading: false,
              failure: false,
              text: `ERROR: Loading the environment file: ${error}`,
            });
          },
        )
        .catch(() => {
          setCurrentState({
            loading: false,
            failure: false,
            text: 'ERROR: Loading the environment file',
          });
        });
    } else {
      startApp();
    }
  }, []);

  if (currentState.loading) {
    return (
      <div
        style={{
          width: '100%',
          height: document.body.clientHeight,
        }}
      >
        <div className="cp-initial-loader">
          <img src={logo} alt="cepsa-logo" />
          <span>CEPSA</span>
        </div>
      </div>
    );
  }
  if (currentState.failure) {
    return (
      <div className="cp-404" style={{ overflow: 'hidden' }}>
        <Row>
          <Col sm={7}>
            <div className="margins">
              <div className="size">{currentState.text}</div>
            </div>
          </Col>
          <Col sm="5">
            <img
              src={Error}
              alt="Error 404"
              className={classNames({
                sizeImage: true,
              })}
            />
          </Col>
        </Row>
      </div>
    );
  }
  return (
    <Provider store={starter.createStore()}>
      <IntlProvider>
        <App />
      </IntlProvider>
    </Provider>
  );
};

export default AppContainer;
