export const HEADER_AUTH = 'x-HEADER-NAME';

export const TODAY = new Date();

export const STATE = Object.freeze({
  NONE: 'NONE',
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
  UPDATED: 'UPDATED',
});

export const FORMAT_DATE = 'dd/MM/yyyy';

export const FORMAT_TIME = 'HH:mm';

export const HTTP_POST = 'POST';

export const DEV = 'development';

export const PATH1 = '/';

export const PATH2 = '/some-path';
