import CalculatorHome from './pages/calc';
import Home from './pages/home';

export default [
  {
    id: 'calc',
    path: '/',
    breadcrumb_link: true,
    exact: true,
    component: CalculatorHome,
    icon: 'icon-optimize',
    isPrivate: false,
  },
  {
    id: 'home',
    path: '/home',
    breadcrumb_link: true,
    exact: true,
    component: Home,
    icon: 'icon-optimize',
    isPrivate: true,
  },
];
