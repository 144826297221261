import { call, put, takeEvery } from 'redux-saga/effects';

import api from '../../apis/apiExample';
import actionCreator from '../../commons/action-helpers';
import HOME_STATE from '../actions/home';

const debug = require('debug')('state:sagas:home');

function* init({ payload }) {
  try {
    // api call
    const response = yield call(api.apiExample, payload);

    yield put(actionCreator(HOME_STATE.HOME_SUCCESS, response));
  } catch (error) {
    debug('Error home in SAGAS', error);
    const { message } = error;
    yield put(actionCreator(HOME_STATE.HOME_FAILURE, message));
  }
}

export default function* sagas() {
  yield takeEvery(HOME_STATE.HOME_REQUEST, init);
}
