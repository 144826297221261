import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import actionCreator from '../../commons/action-helpers';
import { STATE } from '../../config/constants';
import HOME_ACTIONS_TYPES from '../../state/actions/home';

const Home = ({ intl: { formatMessage } }) => {
  const dispatch = useDispatch();

  const { home } = useSelector(
    (stateRdx) => ({
      home: stateRdx.home,
    }),
    shallowEqual,
  );

  // useEffect for first render
  useEffect(() => {
    // effects
    // dispatch actions

    dispatch(
      actionCreator(HOME_ACTIONS_TYPES.HOME_REQUEST, {
        ejemplo: 'valor',
      }),
    );

    // comment to execute only in the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect for success
  useEffect(() => {
    if (home.state === STATE.FETCHING) {
      // console.log('Aqui podemos mostrar un loading....');
    }

    if (home.state === STATE.SUCCESS) {
      /*
            console.log('Aqui obtenemos el retorno de la acción hacemos algo con eso... ');
            console.log('RESPONSE: ', home.data);
            */
    }
  }, [home, home.payload, home.state]);

  return (
    <>
      {formatMessage({
        id: 'homeMessage',
      })}
      <a href="/calc">Calculadora</a>
    </>
  );
};
Home.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Home);
