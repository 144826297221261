import { call, put, takeEvery } from 'redux-saga/effects';

import coinApi from '../../apis/coins';
import actionCreator from '../../commons/action-helpers';
import COINS from '../actions/coins';

const debug = require('debug')('state:sagas:coinList');

function* getList() {
  try {
    // api call
    const response = yield call(coinApi.coinList);

    yield put(actionCreator(COINS.COIN_LIST_SUCCESS, { list: response.data }));
  } catch (error) {
    debug('Error coinList in SAGAS', error);
    const { message } = error;
    yield put(actionCreator(COINS.COIN_LIST_FAILURE, message));
  }
}

export default function* getCoinList() {
  yield takeEvery(COINS.COIN_LIST_REQUEST, getList);
}
