/*
import config from "../config/vars";
import xhr from "../config/axios";
*/

const apiExample = (param) => {
  // codigo hardcodeado para pruebas:
  return [param];

  // codigo normal:
  /*
  return xhr
    .getInstance()
    .post(config.getVars().endpoints.getBusinessRules, product);
  */
};

export default { apiExample };
