import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { intlReducer } from 'react-intl-redux';
import { combineReducers } from 'redux';

import coinList from './coinList';
import getCoin from './getCoin';
import home from './home';

const history = createBrowserHistory({});

const reducers = combineReducers({
  intl: intlReducer,
  router: connectRouter(history),
  home,
  coinList,
  getCoin,
});

export default reducers;
