import { call, put, takeEvery } from 'redux-saga/effects';

import coinApi from '../../apis/coins';
import actionCreator from '../../commons/action-helpers';
import COINS from '../actions/coins';

const debug = require('debug')('state:sagas:getCoin');

function* getCoin({ payload }) {
  try {
    // api call
    const response = yield call(coinApi.getCoin, payload);

    const { data } = response;
    const coin = {
      value: data.id,
      price: data.market_data.current_price.usd,
      label: data.symbol.toUpperCase(),
      updatedAt: data.last_updated,
    };

    yield put(actionCreator(COINS.GET_COIN_SUCCESS, { coin }));
  } catch (error) {
    debug('Error getCoin in SAGAS', error);
    const { message } = error;
    yield put(actionCreator(COINS.GET_COIN_FAILURE, message));
  }
}

export default function* getCoinById() {
  yield takeEvery(COINS.GET_COIN_REQUEST, getCoin);
}
