import HOME_STATE from '../actions/home';
import { STATE } from '../../config/constants';

const defaultState = {
  response: null,
  error: null,
  state: STATE.NONE,
  data: null,
  headers: null,
};

const ACTION_HANDLERS = {
  [HOME_STATE.SET_STATE]: (state, action) => ({
    ...state,
    ...action.payload,
    state: STATE.NONE,
  }),

  [HOME_STATE.HOME_REQUEST]: (state) => {
    return {
      ...state,
      state: STATE.FETCHING,
    };
  },
  [HOME_STATE.HOME_SUCCESS]: (state, action) => {
    // some transformations

    return {
      ...state,
      data: action.payload,
      state: STATE.SUCCESS,
      error: null,
    };
  },

  [HOME_STATE.HOME_FAILURE]: (state, action) => {
    const { payload: error } = action;
    return {
      ...state,
      state: STATE.FAILURE,
      error,
    };
  },
};

export default (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
