export default {
  locale: 'es',
  messages: {
    title: 'CALCULADORA DE CRIPTOMONEDAS',
    'cepsa.api.error.Unauthorized': 'Solicitud no autorizada',
    'cepsa.api.error.BackendUnavailable': 'Servicio no disponible',
    sessionExpired:
      'Su sesión ha expirado, redireccionaremos para obtener unas nuevas credenciales.',
    accept: 'Aceptar',
    loadingUserData: 'Cargando datos del usuario...',
    loadingData: 'Obteniendo datos...',
    mock: 'MOCK MODE',

    errorTitle: 'Opps, hay errores!',
    errorMessage: 'Llama al nene, hay errores!',
    errorLoadingData: 'Error obteniendo datos...',

    navBarItem1: 'ITEM 1',
    navBarItem2: 'ITEM 2',
    navBarItemCalc: 'Crypto Calculadora',

    homeMessage: 'HOME PAGE',

    close: 'CERRAR',
    update: 'ACTUALIZAR',
    retry: 'REINTENTAR',
    loadError: 'ERROR DE CARGA',
    all: 'TODOS',

    USDT: 'USDT',
    recalculate: 'Recalcular',
    coins: 'Crypto Monedas',
  },
};
