import React from 'react';
import PropTypes from 'prop-types';
import { UncontrolledTooltip } from 'reactstrap';

import './CustomTooltipItem.scss';

const CustomTooltipItem = ({ children, ...rest }) => {
  const { target, placement } = rest;
  return (
    <span>
      <UncontrolledTooltip
        target={target}
        placement={placement}
        innerClassName="cp-tooltip"
        arrowClassName="cp-tooltip-arrow"
      >
        {children}
      </UncontrolledTooltip>
    </span>
  );
};

CustomTooltipItem.propTypes = {
  /* Contenido */
  children: PropTypes.node.isRequired,
};

export default CustomTooltipItem;
