import axios from 'axios';
import * as AxiosLogger from 'axios-logger';
// import actionCreator from "../commons/action-helpers"; // helper for action creation
import { HEADER_AUTH } from './constants';

import vars from './vars';

const debug = require('debug')('config:axios');

// axios instance
let instance;

let dispatch;
let formatMessage;

function initialize(options, history) {
  instance = axios.create({
    ...options,
  });

  // REQUEST
  instance.interceptors.request.use(
    (config) => {
      const header = vars.getVars().headerAuth;
      const requestConfig = {
        ...config,
      };
      if (header) {
        requestConfig.headers.common[HEADER_AUTH] = header;
      } else {
        debug('not session!');
      }
      return Promise.resolve(AxiosLogger.requestLogger(requestConfig));
    },
    (error) => Promise.reject(error),
  );

  // RESPONSE
  instance.interceptors.response.use(
    (response) => Promise.resolve(AxiosLogger.responseLogger(response)),
    (error) => {
      const { response } = error;
      if (response) {
        const { status } = response;
        if (status === 401) {
          if (dispatch && formatMessage) {
            // dispatch some action to notify
          } else {
            history.push('/login');
          }
        }
      }
      return Promise.reject(AxiosLogger.errorLogger(error));
    },
  );
}

/**
 * Get axios instance
 * @returns {*}
 */
function getInstance() {
  if (instance) {
    return instance;
  }
  throw new Error('axios is not initialized');
}

function setParams(d, fM) {
  dispatch = d;
  formatMessage = fM;
}

export default {
  initialize,
  getInstance,
  setParams,
};
