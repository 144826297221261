/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';

import logo from '../assets/images/logocalc.svg';
import { NavBar } from '../components';
import { DEV, PATH1, PATH2 } from '../config/constants';

const AppLayout = ({ intl: { formatMessage }, onRendered, children }) => {
  const dispatch = useDispatch();

  const { path } = useSelector(
    (state) => ({
      path: state.router.location.pathname,
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (onRendered) {
      onRendered(dispatch);
    }
  });

  const items = [
    {
      icon: 'icon-optimize',
      option: path && path === '/',
      path: PATH1,
      target: 'item1',
      tooltipText: 'navBarItemCalc',
    },
    {
      icon: 'icon-alarms icon',
      option: path && path.indexOf(PATH2) !== -1,
      path: PATH2,
      target: 'item2',
      tooltipText: 'navBarItemDataState',
    },
  ];

  return (
    <div className="cp-base-layout">
      <NavBar items={items} logo={logo} altLogo="cepsa-logo" />
      {process.env.NODE_ENV === DEV && process.env.REACT_APP_MOCK === 'true' && (
        <div className="mock-mode-indicator">
          {formatMessage({
            id: 'mock',
          })}
        </div>
      )}

      <div className="cp-page-wrapper">
        <div className="page-content">{children}</div>
      </div>
    </div>
  );
};

export default withRouter(injectIntl(AppLayout));
