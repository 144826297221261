import xhr from '../config/axios';
import config from '../config/vars';

const coinList = (param = 'usd') => {
  return xhr
    .getInstance()
    .get(`${config.getVars().endpoints.coinList}${param}`);
};

const getCoin = (param) => {
  return xhr
    .getInstance()
    .get(`${config.getVars().endpoints.getCoin}/${param}`);
};

export default { coinList, getCoin };
