import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import {
  connectRouter,
  routerMiddleware as createRouterMiddleware,
} from 'connected-react-router';

import axios from './config/axios';
import config from './config/vars';
import reducers from './state/reducers';
import sagas from './state/sagas';
import localeUtils from './commons/locale-utils';
import { DEV } from './config/constants';

export const history = createBrowserHistory({});

export default {
  initModules() {
    const { baseUrl } = config.getVars();
    axios.initialize(
      {
        baseURL: baseUrl,
        timeout: 6000 * 20,
      },
      history,
    );
  },

  /**
   * Store creation
   * - Middleware initialization
   * - Enabling devtools extension if not production
   * - Initialize store with intl from getLocale()
   * @returns {Store<any & any> & any}
   */
  createStore() {
    const sagaMiddleware = createSagaMiddleware();
    const routerMiddleware = createRouterMiddleware(history);
    let composeEnhancers = compose;

    if (process.env.NODE_ENV === DEV) {
      const composeWithDevToolsExtension =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
      // Habilita el debug en desarrollo.
      localStorage.debug = 'state:sagas:*';
      if (typeof composeWithDevToolsExtension === 'function') {
        composeEnhancers = composeWithDevToolsExtension;
      }
    }

    const store = createStore(
      connectRouter(history)(reducers),
      { intl: localeUtils.getLocale() },
      composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware)),
    );

    sagaMiddleware.run(sagas);

    return store;
  },
};
