const BASE = {
  baseUrl: 'https://api.coingecko.com/api/v3',
  endpoints: {
    coinList: '/coins/markets?vs_currency=',
    getCoin: '/coins',
  },
  headerAuth: 'AUTH_HEADER',
};

const DEV = {
  baseUrl: 'https://api.coingecko.com/api/v3',
};

const QA = {};

let environment = null;
const setEnvironment = (e) => {
  environment = e;
};

const getEnvironment = () => {
  return environment;
};

const getVars = () => {
  if (process.env.NODE_ENV === 'development') {
    const CONF = process.env.REACT_APP_ENV === 'qa' ? QA : DEV;
    if (process.env.REACT_APP_MOCK === 'true') {
      const { baseUrl, ...MOCK } = CONF;
      return {
        baseUrl,
        ...BASE,
        ...MOCK,
      };
    }
    return {
      ...BASE,
      ...CONF,
    };
  }
  if (environment) {
    return {
      ...BASE,
      ...environment,
    };
  }
  throw Error('No environment config file');
};

export default {
  getVars,
  setEnvironment,
  getEnvironment,
};
